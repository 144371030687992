<template>
    <div class="mobileApproval scrolling">
        <div class="detail">
            <baseInfo :files="files" :myData="myData" :type="$route.query.type" />
        </div>
        <template v-if="approvalFlag == 0">
            <div class="gap"></div>
            <div class="formContainer">
                <mobileApprovalForm @submit="submit" />
            </div>
        </template>
    </div>
</template>
<script>
import { documentBillAudit } from '@/api/modular/fileStream/documentManage'
import { userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import minxin, { billDetail, initWx } from '../components/minxin'
import baseInfo from './components/baseInfo.vue'
import mobileApprovalForm from './components/mobileApprovalForm.vue'
export default {
    mixins: [minxin, billDetail, initWx],
    components: {
        baseInfo,
        mobileApprovalForm,
    },
    computed: {
        approvalFlag() {
            // let
            const { nodes } = this.myData
            let copyList = nodes && nodes.filter((item) => item.sort != -1 && item.sort != 99 && item.sort != 100)
            let flagList =
                copyList &&
                copyList.map((item) => {
                    return item?.nodes?.filter((item) => item.userId == userId)
                })
            flagList = flagList && flagList.filter((item) => item.length).map((item) => item[0])
            if (flagList) {
                let flags = flagList.find((item) => item.approvalFlag == 0)
                return flags?.approvalFlag
            }
        },
    },
    methods: {
        submit(values) {
            const { approvalId, nodeId, id: billId } = this.$route.query
            documentBillAudit({
                approvalId,
                billId,
                nodeId,
                opinion: values.nbyj,
                status: Number(values.spfs),
            })
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('审批成功')
                        this.$nextTick(() => {
                            this.$router.push({ name: 'mobileHome', query: { index: 0 } })
                        })
                    } else {
                        this.$message.error(res.message)
                        this.$nextTick(() => {
                            this.$router.push({ name: 'mobileHome', query: { index: 0 } })
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
</script>
<style lang="less" scoped>
.mobileApproval {
    .detail {
        padding: 15px 12px 12px;
    }
    .gap {
        height: 10px;
        background: #f5f5f5;
        border-radius: 4px;
    }
    .formContainer {
        padding: 0 21px 69px;
    }
}
</style>
